import React, { useState } from "react"
import { useForm } from "react-hook-form";
const TRTForm = ({ dedicatedResource }) => {
    const { register, handleSubmit, reset, errors, getValues,
        formState: {
            isSubmitting
        }
    } = useForm();

    const [isSubmited, setIsSubmited] = useState(false);

    const hasError = field => errors[field] !== undefined;
    const getError = field => hasError(field) && errors[field].message

    const API_URL = 'https://5q0p1oxzc4.execute-api.us-east-1.amazonaws.com/dev';
    const onSubmit = async data => {
        try {
            setIsSubmited(true)
            reset();
            data.to = ["nicky@molenkoning.com"];
            data.from = "nicky@ijzersterk-tekenwerk.nl";
            data.subject = "Reactie van de website";
            data.dedicatedResource = dedicatedResource
            await fetch(API_URL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify(data),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
            setIsSubmited(false)
        } catch (error) {
            setIsSubmited(false)
            // handle server errors
        }
    };

    const validateEmail = _ => {
        const values = getValues({ nest: true });
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (re.test(values.email.toLowerCase()) || 'Please enter valid email');
    }

    return (
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <div className="group">
                <input type="text"
                    name="userName"
                    id="userName"
                    disabled={isSubmitting}
                    ref={register({
                        required: 'Please enter first name',
                    })}
                    placeholder="Jouw naam*"
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                {/* <label>Your Name*</label> */}
                {
                    hasError("userName") && (
                        <span className="form-error" error={toString(hasError("userName"))}>
                            {getError("userName")}
                        </span>
                    )
                }
            </div>
            <div className="group">
                <input type="text"
                    disabled={isSubmitting}
                    name="email"
                    id="email"
                    ref={register({ validate: validateEmail, required: "Please enter email" })}
                    placeholder="Email adres*"
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                {/* <label>Email Address*</label> */}
                {
                    hasError("email") && (
                        <span className="form-error" error={toString(hasError("email"))}>
                            {getError("email")}
                        </span>
                    )
                }
            </div>
            <div className="group">
                <textarea type="textarea"
                    disabled={isSubmitting}
                    name="message"
                    id="message"
                    rows="3"
                    ref={register({ required: "Please enter description" })}
                    placeholder="Korte beschrijving van het werk*"
                ></textarea>
                <span className="highlight"></span>
                <span className="bar"></span>
                {/* <label>Project brief Description*</label> */}
                {
                    hasError("message") && (
                        <span className="form-error" error={toString(hasError("message"))}>
                            {getError("message")}
                        </span>
                    )
                }
            </div>
            <div className="text-right">
                <button type="button" disabled={isSubmitting}
                    type="submit"
                    className="btn btn-trt-primary">
                    {!isSubmited ? <span>Aanvraag sturen</span> : ''}
                    {isSubmited ? <div
                        className="spinner-border text-light"
                        role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : ''}
                </button>
            </div>
        </form>
    )
}

export default TRTForm;