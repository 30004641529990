import React from 'react'
import Layout from '../components/layout'
import trtData from '../content/trt-data.json'
import CollapsePanel from '../components/collapse-panel'
import GetInTouch from '../components/get-in-touch'
import { Link } from 'gatsby'
import TRTForm from '../components/trt-form'
import Metatags from '../components/Metatags'

const HireUsPage = () => {
    return (
        <Layout>
            <section className="trt-hire-developer">
                <div className="hire-developer-banner">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-6">
                                <h1>Huur ons in!</h1>
                                <p className="mb-5">IJzersterk Tekenwerk staat bekend om haar flexibiliteit. Dit laten we graag aan je zien.</p>
                                <div className="developer-quality">
                                    <img src="../images/hire-developer-icon/monthly-hire-icon.png"
                                        alt="" />
                                    <label>Engineers zijn per maand of uur in te huren</label>
                                </div>
                                <div className="developer-quality">
                                    <img src="../images/hire-developer-icon/resource-icon.png"
                                        alt="" />
                                    <label>Onderhoud direct contact met de engineer</label>
                                </div>
                                <div className="developer-quality">
                                    <img src="../images/hire-developer-icon/calendar-icon.png"
                                        alt="" />
                                    <label>Onze tekenaars zijn 160 uur per maand inzetbaar</label>
                                </div>
                                <div className="developer-quality">
                                    <img src="../images/hire-developer-icon/reporting-icon.png"
                                        alt="" />
                                    <label>Ontvang dagelijks, wekelijks of maandelijks een rapportage van ingezette uren</label>
                                </div>
                                <div className="developer-quality">
                                    <img src="../images/hire-developer-icon/tracking-icon.png"
                                        alt="" />
                                    <label>Live inzicht in uitgevoerde of openstaande taken</label>
                                </div>
                                <div className="developer-quality">
                                    <img src="../images/hire-developer-icon/hubstaff-icon.png"
                                        alt="" />
                                    <label>Wij factureren de gemaakte uren helder en transparant</label>
                                </div>
                                <div className="developer-quality">
                                    <img src="../images/hire-developer-icon/timezone-icon.png"
                                        alt="" />
                                    <label>Flexibele uren vanwege verschillende tijdzones</label>
                                </div>
                            </div>
                            <div className="col-lg-5 mt-3 mb-3 mb-lg-0 mt-lg-0">
                                <div className="hire-developer-form">
                                    <h5>Huur ons in!</h5>
                                    <TRTForm dedicatedResource='Hire US'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gradient">
                    <div className="container">
                        <div className="services-provide">
                            <h3>Voordelen van het inhuren van engineers in Vietnam</h3>
                            <CollapsePanel listServices={trtData.benefitsOfHiring} id="benefitsOfHiring"></CollapsePanel>
                        </div>
                    </div>

                    <div className="how-to-hire">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h3>Een engineer inhuren bij IJzersterk Tekenwerk</h3>
                                </div>
                            </div>
                            <p>We volgen een heel simpel proces.</p>
                            <ul>
                                <li>Ten eerste: je neemt contact met ons op en vertelt ons je idee. Als we het samen eens zijn over de kosten en condities vervolgen we onze stappen.</li>
                                <li>Op basis van je aanvraag bekijken we de werkzaamheden en maken we een plan van aanpak.</li>
                                <li>Bij het starten van de werkzaamheden houden we elkaar goed op de hoogte. Je hebt inmiddels een eigen vaste engineer en als dat klikt is hij degene die je belangen behartigd.</li>
                                <li>Tot slot: We zullen doorlopend bekijken of de prestaties van de engineer passen bij het werk dat van hem verlangd wordt. Moeten er meer uren worden ingezet of kunnen we juist afschalen? In sommige gevallen zal er een tweede engineer worden toegevoegd aan jouw team. Alles wordt in overleg besloten en wij handelen niet zonder dat we hiervoor toestemming hebben ontvangen. </li>
                            </ul>
                        </div>
                        <GetInTouch />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default HireUsPage